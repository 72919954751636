
         @import "config.scss";
      
.wrapper {
   position: relative;
   height: 50px;

   .lastStock {
      display: flex;
      @extend %quantity-last-stock;
      margin: 5px 0;
   }

   .button {
      display: flex;
      @extend %button;
      align-items: center;
      outline: none;
      border: unset;
      background-color: unset;
      margin: 5px 0;
      cursor: pointer;

      .count {
         color: $color-quantity-count;
         margin-left: 5px;
      }

      .icon {
         @extend %flexCenter;
         @include transition(0.15s linear);
         width: $icon-size;
         height: $icon-size;

         &--active {
            @include transform(rotate(180deg));
         }
      }
   }
   .open {
      display: contents;
   }
   .noOpen {
      display: none;
   }
}

.text {
   @extend %body-1;
}
