
         @import "config.scss";
      
.wrapper {
   display: flex;
   position: relative;
   background: whitesmoke;

   z-index: 1;
   width: 100%;
   height: 40px;

   > ul {
      display: flex;
      justify-content: space-evenly;
      flex: 1;

      // .navbarItem:not(:nth-child(-n + 3)) {
      //    margin-left: 5px;
      // }
   }
}
@include media('>tablet') {
   .wrapper {
      &--over-content {
         // position: absolute;
         // top: 160px;
      }

      ul {
         justify-content: center;
      }
   }
}
