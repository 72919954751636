
         @import "config.scss";
      
.base {
   @extend %flexCenter;
   @extend %button;
   border-radius: $radius-button;
   @include transition(all 0.25s ease);
   border: none;
   height: 45px;
   cursor: pointer;

   .icon {
      position: relative;
      display: flex;
      margin-right: 5px;

      svg {
         width: 22px;
         height: 22px;
      }

      &--badge {
         position: relative;
      }

      .badge {
         @extend %headline-6;
         border-radius: $radius-icon-badge;
         background-color: var(--color-primary);
         color: var(--color-primary-contrast);
         position: absolute;
         top: -17px;
         right: -10px;
         width: 24px;
      }
   }

   &:hover {
      @include transition(all 0.25s ease);
   }

   &.outlined {
      background-color: $color-btn-outlined-bg;
      color: var(--color-button-text-outlined);

      &:hover {
         color: $color-on-btn-outlined;
      }
   }
}

.primary {
   background-color: var(--color-button);
   color: var(--color-button-text-primary);

   &.outlined {
      border: $border-button solid var(--color-primary);

      &:hover {
         background-color: $color-on-btn-primary-outlined-bg;
         color: var(--color-primary-contrast);
         border: $border-button solid $color-on-btn-primary-outlined-bg;
      }
   }
}

.secondary {
   background-color: #d8d6d6;
   color: var(--color-button-text-secondary);

   // FIXME: Arreglar outlined de secondary, si es que se va a usar
   &.outlined {
      border: $border-button solid $color-btn-secondary;
   }

   &.outlined {
      border: $border-button solid $color-btn-secondary-border;

      &:hover {
         background-color: $color-on-btn-secondary-outlined-bg;
      }
   }
}
.success {
   background-color: #3adb15;
}
.warning {
   background-color: var(--color-warning-botton);
   color: var(--color-warning-text);

   // FIXME: Arreglar outlined de secondary, si es que se va a usar
   &.outlined {
      border: $border-button solid $color-btn-secondary;
   }

   &.outlined {
      border: $border-button solid $color-btn-secondary-border;

      &:hover {
         background-color: $color-on-btn-secondary-outlined-bg;
      }
   }
}

.primary-inverse {
   color: $color-btn-primary-bg;
   background-color: var(--color-primary-contrast);
}

.disabled {
   @include opacity(0.5);
   color: $color-btn-disabled;
   background-color: $color-btn-disabled-bg;
   cursor: default;

   &:hover {
      background-color: $color-on-btn-disabled-bg;
   }
}

.cancel {
   background-color: #d8d6d6;
}

.back {
   background-color: #d8d6d6;
}

.iconRight {
   flex-flow: row-reverse nowrap;
}

@include media('>=tablet') {
   .base {
      min-width: 250px;
   }

   .base,
   .primary,
   .secondary .primary-inverse {
      &:hover {
         transform: scale(1.05);
      }
   }
}
