
         @import "config.scss";
      
.wrapper {
   @include borderRadius($radius-table);
   border: $border-table solid $color-table-border;

   .table {
      @extend %table;
      @include borderRadius($radius-table);
      width: 100%;

      .header {
         display: none;
      }

      .body {
         width: 100%;
      }

      .messageStock {
         display: block;
         @extend %body-1;
         color: $color-red;
         background-color: #ffdada;
         border: 1px solid $color-red;
         padding: 10px;
         border-radius: 5px;
         margin-bottom: 10px;
      }
   }
}

// Tablet
@include media('>=tablet') {
   .wrapper {
      .table {
         .header {
            display: flex;
            padding: 0 20px;

            span {
               font-weight: 700;
               padding: 16px 0;
            }

            .item {
               width: calc(40% + 100px);
            }

            .quantity,
            .price,
            .remove {
               width: 20%;
            }
         }
      }
   }
}
