
         @import "config.scss";
      
.wrapper {
   height: 100%;
   flex: 1;

   .link {
      @extend %flexCenter;
      @extend %caption;
      color: #000;
      text-align: center;
      height: 100%;
      flex: 1;
      cursor: pointer;
      font-weight: 700;
      padding: 0 5px;

      &--active,
      &:hover,
      &:focus {
         outline: none;
      }
   }
}

@include media('>=tablet') {
   .wrapper {
      width: auto;
      flex: none;

      .link {
         font: {
            size: 0.875rem;
         }
         letter-spacing: 0.078125rem;
         padding: 0 10px;
      }
   }
}
