
         @import "config.scss";
      
.container {
   display: block;
   height: 100%;
   width: 100%;
   border-radius: 10px;
   animation: load 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes load {
   0% {
      background-color: rgb(245, 245, 245);
   }
   50% {
      background-color: rgb(235, 235, 235);
   }
   100% {
      background-color: rgb(245, 245, 245);
   }
}
