
         @import "config.scss";
      
.powered {
   background-color: var(--color-secondary);
   font-family: Montserrat;
   color: #d1d3d4;
   font-weight: 500;
   display: flex;
   justify-content: center;
   padding-top: 3rem;
   gap: 0.5rem;
   flex-direction: column;
   align-items: center;
}
