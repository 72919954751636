
         @import "config.scss";
      
.container {
   display: flex;
   justify-content: center;
   position: fixed;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   right: 0;
   margin: auto;
   z-index: 10;
   padding: 10vh 0;
   // background-color: rgb(0 0 0 / 70%);
}

.spinnerContainer {
   display: flex;
   align-items: center;
}
