
         @import "config.scss";
      
.wrapper {
   width: 100%;
   .title {
      @extend %caption;
      color: $color-title;
      font-weight: 700;
      align-items: center;
      justify-content: space-between;
      display: flex;
      border-bottom: 1px solid $color-title-border;
      padding: 10px 5px;

      &:active,
      &:hover,
      &:focus {
         border-radius: $border-select;
         background-color: $color-on-select;
         cursor: pointer;
      }

      .icon {
         width: $icon-size;
         height: $icon-size;
      }
   }
}

@include media('>=tablet') {
   .wrapper {
      padding: 0 15px;

      .title {
         margin-bottom: 5px;
         &:active,
         &:hover,
         &:focus {
            border-radius: none;
            background-color: transparent;
            cursor: initial;
         }

         .icon {
            display: none;
         }
      }
   }
}
