
         @import "config.scss";
      
.content {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   padding: 30px;
}
.containerLogo {
   padding: 20px;
   display: flex;
   align-items: center;
   flex-direction: column;
}

.containerCenter {
   display: flex;
   flex-direction: column;
}

.footerMain {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
}

.containerNets {
   display: flex;
   align-items: center;
   margin-top: 1rem;
   flex-direction: column;
   gap: 1rem;
}
.containerQuestion {
   display: flex;
   justify-content: flex-start;
   flex-wrap: wrap;
   padding: 30px;
}
.questions {
   padding: 20px;
   box-sizing: border-box;
}

// .image {
//    margin-left: 1rem;
// }

.spanSeguinos {
   font-family: Montserrat;
   color: $color-grey-200;
   font-weight: 500;
}

@include media('<=tablet') {
   .content {
      flex-direction: column;
      padding: 5px;
   }
   .containerLogo {
      height: 100px;
      padding: 0;
   }
   .questions {
      padding: 5px;
      padding-left: 15px;
      box-sizing: border-box;
   }
}

@include media('<800px') {
   .footerMain {
      flex-direction: column;
   }
}
