
         @import "config.scss";
      
.container {
   width: 100%;
   margin: 10px;
   
}
.title {
   @extend %headline-6;
   color: $color-grey-200;
   font-weight: 500;
   margin-bottom: 15px;
   margin-left: -15px;
   
}

.questions {
   list-style-type: disc;
   color: $color-grey-200;

   li {
      cursor: pointer;
      color: $color-grey-200;
      margin-bottom: 10px;

      a {
         @extend %subtitle-2;
         color: $color-grey-200;
      }
   }
}

@include media('<tablet') {
   .container {
      margin-right: 20px;
   }

   .questions li {
      margin-bottom: 15px;
   }
}
