
         @import "config.scss";
      
.wrapper {
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
   z-index: 5;
   top: 0;
   width: 100%;
   padding: 5px;
   background-color: var(--color-header-bg);
   gap: 5vw;

   .imageCity {
      margin-left: 20px;
   }
}
