
         @import "config.scss";
      
.wrapper {
   display: flex;
   flex-direction: column;
   box-sizing: border-box;
   width: 100%;
   padding: 5px;
   margin: 8px 0 4px;
   position: relative;
   .label {
      @extend %label;
      margin-bottom: 4px;

      &--required::after{
         content: "*";
         font-weight: bold;
         padding-left: 5px;
         align-self: center;

      }
   }
   .errorMessage {
      @extend %error;
      border-color: $color-red;
      margin-top: 4px;
      font-weight: 700;
   }
   input {
      @extend %subtitle-1;
      @include borderRadius($radius-input);
      border: $border-input solid $color-input-border;
      height: 45px;
      width: 100%;
      padding: 0 8px;
      &:focus {
         border: $border-input solid $color-input-on-border;
         outline: none;
      }
      &[type='date']::-webkit-calendar-picker-indicator {
         cursor: pointer;
      }
   }
   &--error {
      input {
         border: 2px solid $color-red !important;
      }
   }
   .iconRight {
      position: absolute;
      right: 10px;
      width: 31px;
      height: 45px;
   }
   .inputWithPrepend {
      position: relative;
      display: flex;
      
      align-items: stretch;
      width: 100%;
      input {
         width: unset;
         flex: 1 1 auto;
         border-top-left-radius: 0;
         border-bottom-left-radius: 0;
      }
   }
   .prepend {
      display: flex;
      margin-right: -1px;
   }
   .inputGroupText {
      @extend %subtitle-1;
      display: flex;
      align-items: center;
      padding: .375rem .75rem;
      margin-bottom: 0;
      background-color: #D8D6D6;
      text-align: center;
      white-space: nowrap;
      border: $border-input solid $color-input-border;
      @include borderRadius($radius-input);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
   }
}
@include media('>=tablet') {
   .fullWidth {
      width: 100%;
   }
   .halfWidth {
      width: 50%;
   }
   .smallWidth {
      width: 25%;
   }
}