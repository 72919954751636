
         @import "config.scss";
      
%wrapper {
   @extend %flexCenter;
   width: 100%;
}

%content {
   display: flex;
   width: 100%;
   flex-direction: column;
}

.container {
   margin: auto;
   position: relative;
   z-index: 2;
}

/* Contenedor de TopInfoBar */
.wrapperTop {
   width: 100%;
   background-color: var(--color-primary);

   .wrapperTopSecond {
      display: flex;
      flex-direction: column;
      max-width: $breakpoint-view;
      margin: auto;

      .topInfoBar {
      }

      .wrapperLogo {
         display: flex;
         align-items: center;
         width: 100%;
         height: 60px;
         order: 1;

         .fillBackground {
            display: none;
         }

         .logo {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            height: 100%;
            // padding: 10px 15px;
            background-color: #2d2d2d;
            transition: all 0.25s ease;
            padding: 1rem;

            div {
               width: 100px !important;
               height: 30px !important;
               opacity: 0.8;
               transition: all 0.25s ease;
            }
            &--unico {
               img {
                  height: 60px !important;
               }
            }
            &--active {
               background-color: var(--color-header-bg);
               width: 100%;

               div {
                  width: 160px !important;
                  height: 45px !important;
                  opacity: 1;
               }
            }

            &--primary {
               div {
                  opacity: 1;
               }
            }
         }
         .text {
            @extend %headline-5;
            width: 100%;
            color: var(--color-primary);
            font-weight: bold;
         }
      }

      .wrapperOneLogo {
         width: 100%;
         // height: clamp(40px, 11vw, 80px);
         position: relative;
         order: 1;

         .fillBackground {
            display: none;
         }

         .logo {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100px; // 100%
            // padding: 10px 15px;
            background: rgb(0, 0, 0);
            padding: 1rem;
            // background: linear-gradient(90deg, rgba(0, 0, 0, 1) 50%, rgba(227, 30, 37, 1) 50%);

            // div {
            //    width: 100% !important;
            //    height: 100% !important;
            //    opacity: 0.8;
            //    transition: all 0.25s ease;
            // }

            &--active {
               background-color: var(--color-header-bg);
               // width: 100%;

               div {
                  // width: 100% !important;
                  // height: 100% !important;
                  opacity: 1;
               }
            }

            &--primary {
               div {
                  opacity: 1;
                  // width: 100%;
                  // height: 100%;
               }
            }
         }
         .text {
            @extend %headline-5;
            // width: 100%;
            color: var(--color-primary);
            font-weight: bold;
         }
      }
   }
}

/* Contenedor de Logo - SearchBar - Cart */
.wrapperMedium {
   @extend %wrapper;
   margin: auto;
   background-color: var(--color-header-bg);
   transition: all 0.25s ease;

   .content {
      @extend %content;
      // align-items: center;
      // justify-content: space-between;
      // flex-wrap: wrap;
      padding: 15px 0;
      gap: 1rem;
      max-width: 1440px;
      width: 100%;

      .loginLink {
         @extend %button;
         display: flex;
         align-items: center;
         border-radius: 4px;
         color: var(--color-button-text-outlined);
         border: 2px solid var(--color-primary);
         height: 45px;
         padding: 0 6px;
         margin-right: 15px;
         transition: all 0.25s ease;
         white-space: nowrap;

         svg {
            margin-right: 5px;
         }

         &:hover {
            background-color: var(--color-primary);
            color: var(--color-primary-contrast);
            border: 2px solid var(--color-primary);
            transform: scale(1.05);
         }
      }
      .searchBar {
         width: 100%;
         order: 3;
         // margin: 0 30px;
         z-index: 2;
         padding: 0 1rem;
      }
      .cartLogin {
         display: flex;
         align-items: center;
         justify-content: center;
         // width: 100%;
         order: 2;
         // margin-bottom: 25px;
      }
   }
}

@include media('>tablet') {
   .container {
      z-index: 2;
   }

   .wrapperTop {
      // flex-direction: row;
      // max-width: 1440px;
      // height: 60px;
      .wrapperTopSecond {
         flex-direction: row;

         .wrapperLogo {
            // width: 40%;

            .logo {
               // width: 50%;

               &--active {
                  // width: 100%;
               }
            }

            .fillBackground {
               // width: 100%;
               // height: 80px;
               display: flex;
               position: absolute;
               top: 0;
               left: 0;
               right: 0;
               margin: auto;
               z-index: -1;

               .logos,
               .infoBar {
                  // width: 100%;
                  // height: 100%;
               }

               .logos {
                  background-color: var(--color-secondary);
               }
               .infoBar {
                  background-color: var(--color-primary);
               }
            }
         }
         .wrapperOneLogo {
            width: 100%;
            height: 82.5px;

            .logo {
               width: 100%;

               &--active {
                  // width: 100%;
               }
            }

            .fillBackground {
               width: 100%;
               height: 60px;
               display: flex;
               position: absolute;
               top: 0;
               left: 0;
               right: 0;
               margin: auto;
               z-index: -1;

               .logos,
               .infoBar {
                  // width: 100%;
                  // height: 100%;
               }

               .logos {
                  background: rgb(0, 0, 0);
                  width: 100%;
                  // background: linear-gradient(90deg, rgba(0, 0, 0, 1) 50%, rgba(227, 30, 37, 1) 50%);
               }
               .infoBar {
                  background-color: var(--color-primary);
               }
            }
         }

         .topInfoBar {
         }
      }
   }

   .wrapperMedium {
      padding: unset;

      .content {
         justify-content: center;
         padding: 1rem 2rem;
         flex-direction: row;

         .logo {
            width: 20%;
         }
         .searchBar {
            order: 2;
            // width: 40%;
         }
         .cartLogin {
            width: unset;
            margin-bottom: unset;
         }
      }
   }

   .wrapperBottom {
      top: 170px;

      .content {
         .user {
            display: flex;
            align-items: center;
            justify-content: center;
            // width: 15%;
         }
      }
   }
}

.images {
   margin: 1rem;
}

.newLogo {
   width: 100%;
   background-color: var(--color-primary);
}
