
         @import "config.scss";
      
.button {
   min-width: unset;
}

@include media('>=tablet') {
   .button {
      min-width: 120px;
   }
}
