
         @import "config.scss";
      
.wrapper {
   position: fixed;
   width: 100%;
   top: 0;
   z-index: 100001;

   .modal {
      display: flex;
      flex-direction: column;
      background-color: #000;
      color: var(--color-secondary-light);
      box-shadow: 0 10px 30px rgb(0 0 0 / 23%);
      width: 100%;
      padding: 16px;
      top: 0;
      position: absolute;

      .cartItemList {
         display: none;
      }

      .message {
         margin-bottom: 10px;
      }

      .buttons {
         display: flex;
         justify-content: center;

         .cartButton {
            display: none;
         }

         .checkoutButton {
         }
      }
   }
}

// Desktop
@include media('>=tablet') {
   .wrapper {
      height: 100%;

      .background {
         width: 100%;
         height: 100%;
         background-color: $color-modal-background;
      }

      .modal {
         justify-content: space-between;
         background-color: $color-modal-bg;
         width: 600px;
         max-height: 510px;
         padding: 16px;
         top: 140px;
         right: 160px;
         border-radius: 5px;

         .message {
            display: none;
         }

         .cartItemList {
            display: block;
            overflow-y: auto; //(desplegar el selector de unidades)
         }

         .buttons {
            display: flex;
            justify-content: center;

            .cartButton {
               display: block;
            }

            .checkoutButton {
               margin-left: 10px;
               width: unset;
            }
         }
      }
   }
}

.btnmodal {
   background-color: #f7f7f7;
}
