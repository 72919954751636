
         @import "config.scss";
      
.wrapper {
   width: 136px;
   padding: 0 5px;

   a:hover > .name {
      background-color: var(--color-secondary);
      color: var(--color-secondary-contrast);
   }

   .image {
      @extend %img-responsive;
      @extend %no-image-text;
      min-height: 126px;
      background-color: $color-image-bg;
   }

   .name {
      @extend %caption;
      background-color: var(--color-primary);
      color: var(--color-primary-contrast);
      text-align: center;
      font-weight: 700 !important;
      transition: all 0.25s ease;
      flex-direction: column;
      display: flex;
      padding: 10px 5px;
   }
}
