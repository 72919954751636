
         @import "config.scss";
      
.container {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: row;

   .image {
      display: flex;
      align-items: center;
      margin: 5px 1vw;
      max-width: 140px;
      img {
         border-radius: 15px;
      }
   }
   .imageMobile {
      display: none;
   }
}
.newsletter {
   display: flex;
   align-items: center;
   justify-content: center;
   margin: auto;
   padding: 20px;
   width: 100%;
   box-sizing: border-box;

   .textNewsletter {
      position: relative;
      top: 10px;
      margin-right: 20px;
      margin-bottom: -53px;
   }

   .title {
      @extend %headline-5;
      color: var(--color-text-newsletter);
      font-weight: 800;
      text-align: right;
      z-index: 0;
   }

   .subtitle {
      @extend %subtitle-2;
      color: var(--color-text-newsletter);
      font-size: 0.9rem;
      text-align: center;
      z-index: 0;
      padding: 1.5rem;
      line-height: 1.3rem;
   }
}

.containerForm {
   display: flex;
   flex-direction: column;
   align-items: center;
   box-sizing: border-box;

   label {
      color: var(--color-primary-contrast);
   }

   .containerTextAlert {
      position: absolute;
      bottom: 5px;
      right: 35%;
      display: flex;
      text-align: center;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
   }

   .containerFormActions {
      display: flex;
      align-items: center;
      position: relative;
      left: 0;

      input {
         padding: 10px;
         padding-left: 1.5rem;
         border-radius: 40px;
         width: 550px;
      }
      .button {
         color: var(--color-button-newsletter);
         background-color: var(--background-button-newsletter);
         z-index: 0;
         border-radius: 40px;
         box-shadow: 10px 10px 16px -5px rgba(0, 0, 0, 0.75);
         margin-top: 4px;
         position: absolute;
         right: 0;
      }
   }
}
@media (max-width: 600px) {
   .container {
      flex-direction: column;

      .image {
         display: none;
      }
      .imageMobile {
         display: block;
      }
   }
}

@include media('<=tablet') {
   // .subtitle {
   //    display: none;
   // }
   .newsletter {
      display: flex;
      flex-direction: column;
      .image {
         display: none;
      }
      .textNewsletter {
         position: relative;
         top: 10px;
         margin-right: 0;
         margin-bottom: 5px;
      }
   }
   .containerFormActions {
      .input {
         max-width: 350px;
      }
      .button {
         position: absolute;
         right: 2%;
      }
   }
   .containerFormActions input {
      max-width: 340px;
   }
}
