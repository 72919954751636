
         @import "config.scss";
      
.container {
   display: flex;
   @extend %boxShadowMenu;
   flex-flow: column wrap;
   background-color: $color-dropdown-menu;
   position: absolute;
   top: 130px;
   z-index: 10;
   @extend %flexCenter;
   padding-top: 10px;
   @extend %caption;
   .items {
      width: 150px;
      padding: 10px 20px;
      .title {
         span {
            font-weight: 700;
         }
      }
      .line {
         opacity: 0.5;
      }

      .item {
         &:hover {
            font-weight: 700;
            cursor: pointer;
         }
         @extend %flexCenter;
         justify-content: start;
         @extend %caption;
         margin: 10px 0;
         .icon {
            color: $color-black-300;
            margin-right: 5px;
         }
         a {
            color: $color-black-400;
            &:hover {
               color: $color-black-400;
               font-weight: 700;
               cursor: pointer;
            }
            @extend %flexCenter;
            @extend %caption;
         }
      }
   }
}

@include media('<tablet') {
   .container {
      width: 100%;
      top: 160px;
      left: 0;
      .items {
         width: 100%;
         .item {
            margin: 20px 0;
         }
      }
   }
}
