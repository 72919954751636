
         @import "config.scss";
      
.wrapper {
   display: flex;
   position: relative;
   width: 100%;
   padding: 16px 0;
   border-bottom: 1px solid rgba(0, 0, 0, 0.05);

   &:last-child {
      border-bottom: unset;
   }

   .loading {
      @extend %flexCenter;
      background-color: $color-loading-bg;
      color: $color-loading;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
   }

   .image {
      background-color: $color-image-bg;
      width: 80px;
      height: 80px;
      margin-right: 10px;
   }

   .wrapperRight {
      display: flex;
      flex-direction: column;

      .wrapperTop {
         display: flex;
         flex-direction: row-reverse;
         justify-content: flex-end;
         flex-wrap: wrap;
         width: 100%;

         img {
            padding-right: 0;
         }
         .name {
            @extend %cart-item-name;
         }
         .colorSize {
            @extend %label;
         }
         a{
            text-decoration: none;
            color:#333;
            &:hover{
               color:#428bca;
            }
         }
      }
   }

   .quantity {
      display: flex;
      align-items: center;
   }

   .prices {
      display: flex;
      align-items: center;

      .current {
         @extend %cart-item-price;
      }
      .original {
         @extend %cart-item-original-price;
         padding-left: 4px;
      }
   }

   .remove {
      display: flex;
      width: fit-content;
      background-color: unset;
      border: unset;
      padding: 0;
      margin-top: 15px;
      cursor: pointer;
      align-items: center;
   }

   .unavailable {
      color: red;
      @extend %body-2;
      font-weight: 700;
      margin-top: 10px;
   }
}

// Tablet
@include media('>=tablet') {
   .wrapper {
      width: 100%;

      .wrapperRight {
         flex-direction: row;
         width: 100%;
         flex-wrap: wrap;

         .wrapperTop {
            width: 40%;
            order: 1;
         }
         .quantity {
            width: 20%;
            order: 2;
         }

         .prices {
            width: 20%;
            order: 3;
            flex-wrap: wrap;
            height: fit-content;
            align-self: center;

            &--no-stock {
               width: 40% !important;
               justify-content: center;
            }
         }
         .remove {
            order: 4;
            margin: unset;
         }
      }
   }
}
