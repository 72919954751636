
         @import "config.scss";
      
.container {
   position: relative;

   .wrapperInput {
      display: flex;
      align-items: center;
      border-radius: 40px;
      box-shadow: 0 5px 10px #00000066;
      height: 42px;
      background-color: #ffffff;
      width: 100%;
      max-width: 55rem;

      svg {
         margin-left: 10px;
         color: $color-black-400;
         height: 20px;
         min-width: 20px;
      }

      input {
         @extend %subtitle-1;
         width: 100%;
         border: unset;
         margin: 10px 4px;
         padding: 4px;
         border-radius: 4px;
         border: 2px solid transparent;
         text-overflow: ellipsis;
         white-space: nowrap;
         overflow: hidden;

         &:focus {
            outline: unset;
            border: 2px solid transparent;
         }

         &::placeholder {
            @extend %subtitle-2;
         }
      }

      button {
         @extend %removeButtonStyle;
         @extend %button;
         background-color: var(--color-primary);
         color: var(--color-primary-contrast);
         height: 100%;
         padding: 0 16px;
         text-align: center;
         cursor: pointer;
         border-radius: 80px;
         transition: all 0.25s;
         // transform: scale(1.1);

         &:active {
            transform: scale(1);
         }
      }
   }
}

@include media('>=tablet') {
   .container {
      .wrapperInput {
         button {
            width: 200px;
            transform: unset;

            &:hover {
               // transform: scale(1.1);
            }
         }
      }
   }
}
