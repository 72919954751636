
         @import "config.scss";
      
.containerSupport {
   display: flex;
   align-items: center;
   color: var(--color-secondary-light);

   a:link {
      color: #fff;
   }
}

.title {
   @extend %headline-5;
   font-size: 1.2rem;
}
.support {
   li {
      color: #fff;
      text-align: left;
      display: block;
      margin-bottom: 5px;
   }
}
.contact {
   @extend %headline-6;
   color: var(--color-secondary-light);
   font-weight: 600;
   font-size: 1.1rem;
}
.attentionDays {
   @extend %subtitle-1;
   font-size: 0.9rem;
}

.icon {
   margin-right: 10px;
}

@include media('<tablet') {
   .icon {
      width: 60px;
      margin-right: 10px;
   }
   .containerSupport {
      display: flex;
      align-items: center;
      color: var(--color-secondary-light);
      margin-bottom: 50px;
   }
}
