
         @import "config.scss";
      
.wrapper {
   // width: 20%;
   height: 100%;
   flex: 1;

   .content {
      @extend %flexCenter;
      height: 100%;
      flex: 1;
      cursor: pointer;

      &--active,
      &:hover,
      &:focus {
         outline: none;
         background-color: #d1d3d4;
      }

      .label {
         @extend %flexCenter;
         @extend %caption;
         height: 100%;
         font-weight: 700;
      }

      .icon {
         @include transition(0.15s linear);
         @extend %flexCenter;
         width: $icon-size;
         height: $icon-size;

         &--active {
            @include transform(rotate(180deg));
         }
      }
   }
}

.placeholder {
   margin: 0 10px;

   @include media('>=tablet') {
      width: 90px;
      margin: 0;
   }
}

@include media('>=tablet') {
   .wrapper {
      width: auto;
      flex: none;

      .content {
         padding: 0 10px;

         .label {
            font: {
               size: 0.875rem;
            }
            letter-spacing: 0.078125rem;
         }
      }
   }
}
