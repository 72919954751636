
         @import "config.scss";
      
.wrapper {
   display: flex;
   @extend %badge;
   @include borderRadius($radius-badge);
   align-items: center;
   display: inline-flex;
   margin: 5px 0;
   padding: 4px 7px;

   .icon {
      margin-right: 5px;
      svg {
         width: $icon-size;
         height: $icon-size;
      }
   }

   &--featureSpecial {
      margin: 8px;
      width: min-content;
   }

   &--uppercase {
      text-transform: uppercase;
   }

   &--text {
      padding: 0 !important;
   }
}

.productBadge {
   margin-right: 10px;
}

.cartMessage {
   font-size: 1rem;
}
