
         @import "config.scss";
      
%wrapper {
   @extend %flexCenter;
   width: 100%;
}

%content {
   display: flex;
   width: 100%;
   max-width: $breakpoint-view;
}

.wrapperTop {
   background-color: $color-blue-300;
   position: relative;
}

.wrapperMedium {
   background-color: var(--color-secondary);
}

.wrapperBottom {
   @extend %wrapper;
   background-color: var(--color-secondary);
   // height: 250px;
   padding-top: 4rem;

   .content {
      display: flex;
      width: 100%;
   }
}

@include media('<=phone') {
   .wrapperMedium {
      flex-direction: column;
   }

   .wrapperBottom {
      display: flex;
      align-items: center;
      height: fit-content;
      padding-top: 20px;
   }
}
