
         @import "config.scss";
      
.container {
   position: absolute;
   top: 50px;
   background-color: white;
   width: 100%;
   padding: 12px 0;
   border-radius: 20px;
   box-shadow: 0 5px 10px #00000066;
   overflow: hidden;

   .title {
      @extend %body-2;
      padding: 10px 16px;
      color: black;
      display: block;
      font-weight: 500;
   }

   .item {
      @extend %body-1;
      display: flex;
      align-items: center;
      padding: 0 16px;
      height: 50px;
      color: #8c8c8c;

      &:hover {
         background-color: #f0f0f0;
      }

      span {
         flex: 1;
         text-overflow: ellipsis;
         white-space: nowrap;
         overflow: hidden;
      }

      svg {
         width: 18px;
         height: 18px;
         margin-right: 10px;
         color: $color-black-150;
      }
      .tag {
         padding: 6px 10px;
         border-radius: 5px;
         font-weight: 500;
      }
      .tagVaypol {
         background: #ffd100;
         color: #000;
      }
      .tagCity {
         background: #b02460;
         color: #fff;
      }
   }

   .separator {
      width: 100%;
      height: 1px;
      background-color: $color-black-100;
      margin-bottom: 16px;
   }
}
