
         @import "config.scss";
      
.wrapper {
   flex-direction: column;
   @include transition(all 0.25s ease);
   background-color: $color-dropdown-menu;
   position: absolute;
   overflow-y: auto;
   top: 15px;
   left: 10px;
   right: 10px;
   bottom: 40px;
   margin-left: 100vw;
   z-index: 2;

   &--active {
      margin-left: 0;
      overflow-x: hidden;
   }

   .back {
      display: flex;
      @extend %back;
      align-items: center;
      padding: 5px;

      .icon {
         width: $icon-size;
         height: $icon-size;
      }

      &:hover,
      &:active,
      &:focus {
         cursor: pointer;
      }
   }

   .item,
   .seeAll {
      margin: 5px 0;
      padding: 5px;

      a {
         @extend %caption;
         margin: 5px 0;
         display: block;
      }
   }

   .item {
      a {
         color: black;
      }
   }

   .seeAll {
      a {
         font-weight: 700;
      }
   }
}

// Desktop
@include media('>=tablet') {
   .wrapper {
      position: relative;
      top: initial;
      left: initial;
      right: initial;
      overflow-y: initial;
      z-index: initial;
      margin-left: 0;
      bottom: 0;

      .item,
      .seeAll {
         margin: 0;
      }

      .back {
         display: none;
      }
   }
}
.single {
   justify-content: flex-start !important;
}
