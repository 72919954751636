
         @import "config.scss";
      
.container {
   @extend %body-2;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: var(--color-primary);
   flex-wrap: wrap;
   min-height: 30px;

   div {
      display: flex;
   }

   // height: 40px;
   svg,
   span {
      color: var(--color-primary-contrast);
   }

   svg {
      width: 22px;
      height: 22px;
      min-width: 22px;
      min-height: 22px;
      margin-left: 10px;
      margin-right: 5px;
   }
   .text {
      display: none;
   }
   .bold {
      font-weight: 700;
      white-space: nowrap;
   }
}

@include media('>tablet') {
   .container {
      // height: 100%;
      width: 60%;
      order: 2;
      justify-content: flex-start;
      padding: 0 20px;
      // width: 100%;

      .text {
         display: block;
         // margin-right: 2vw;
      }
   }
}
