
         @import "config.scss";
      
.wrapper {
   height: 100%;
   list-style: none;
   display: inherit;
   .loginLink {
      @extend %button;
      display: flex;
      align-items: center;
      border-radius: 4px;
      color: var(--color-button-text-outlined);
      border: 2px solid var(--color-button);
      height: 45px;
      padding: 0 6px;
      margin-right: 15px;
      transition: all 0.25s ease;

      svg {
         margin-right: 5px;
      }

      &:hover {
         background-color: var(--color-primary);
         color: var(--color-button-text-hover);
         border: 2px solid var(--color-primary);
      }
   }
   .content {
      @extend %flexCenter;
      height: 100%;
      flex: 1;
      cursor: pointer;

      .label {
         @extend %flexCenter;
         height: 100%;
      }

      .icon {
         @include transition(0.15s linear);
         @extend %flexCenter;
         width: $icon-size;
         height: $icon-size;

         &--active {
            @include transform(rotate(180deg));
         }
      }
   }
}

.placeholder {
   margin: 0 10px;

   @include media('>=tablet') {
      width: 90px;
      margin: 0;
   }
}

@include media('>=tablet') {
   .wrapper {
      width: auto;

      .content {
         padding: 0 10px;
      }
   }
}
