
         @import "config.scss";
      
.loader,
.loader:after {
   border-radius: 50%;
   width: 25px;
   min-width: 25px;
   height: 25px;
}
.loader {
   margin-left: 10px;
   border: 3px solid rgba(var(--color-spinner-btn), 0.5);
   border-left: 3px solid rgb(var(--color-spinner-btn));
   -webkit-transform: translateZ(0);
   -ms-transform: translateZ(0);
   transform: translateZ(0);
   -webkit-animation: load8 1.1s infinite linear;
   animation: load8 0.7s infinite linear;
}
@-webkit-keyframes load8 {
   0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
   }
   100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
   }
}
@keyframes load8 {
   0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
   }
   100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
   }
}

.loaderLarge,
.loaderLarge:after {
   border-radius: 50%;
   width: 50px;
   min-width: 50px;
   height: 50px;
}
.loaderLarge {
   border: 5px solid rgba(var(--color-spinner-fill), 0.2);
   border-left: 5px solid rgb(var(--color-spinner-fill));
}

.loadingData {
   border: 3px solid rgba(var(--color-spinner-data), 0.5);
   border-left: 3px solid rgb(var(--color-spinner-data));
}
